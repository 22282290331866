import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { HttpClient } from '@angular/common/http';


@Component({
	selector: 'app-referral-redirect',
	templateUrl: '../../../layout/attempting-login/attempting-login.component.html',
	styleUrls: ['../../../layout/attempting-login/attempting-login.component.scss'],
})
export class ReferralRedirectComponent implements OnInit {
	protected readonly isBrowser: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		protected readonly affiliateService: AffiliateService,
		protected readonly route: ActivatedRoute,
		protected readonly router: Router,
		private http: HttpClient,
		protected readonly trackerService: TrackerService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	protected async saveReferralCode(): Promise<void> {
		if (!this.isBrowser) return;

		const referrerUserId = await this.getReferralCodeFromRoute();
		if (!referrerUserId) return;

		this.affiliateService.saveReferralCode(referrerUserId);
	}

	protected async getReferralCodeFromRoute(): Promise<string> {
		const referralCode = this.route.snapshot.paramMap.get('code'); // referrer user id for direct referrals for pro etc
		if (referralCode) return referralCode;

		const affiliateCode = this.route.snapshot.paramMap.get('affiliateCode'); // affiliate alias, e.g., `shodesu`
		if (affiliateCode) {
			await this.affiliateService.getAffiliate(affiliateCode);
			const referralCodeFromAffiliateCode = this.affiliateService.getReferralCodeFromAffiliateCode(affiliateCode);
			if (referralCodeFromAffiliateCode) return referralCodeFromAffiliateCode;
		}

		return '';
	}

	private async fetchIPAndRegion(): Promise<{ ip: string; continentCode: string, countryName: string }> {
		const geoApiUrl = 'https://ipapi.co/json/';
		try {
			const response: any = await this.http.get(geoApiUrl).toPromise();
			return { ip: response.ip, continentCode: response.continent_code, countryName: response.country_name };
		} catch (error) {
			console.error('Error fetching IP and region', error);
			return { ip: 'Unknown', continentCode: 'Unknown', countryName: 'Unkown' };
		}
	}


	public async ngOnInit(): Promise<void> {
		const affiliate = this.route.snapshot.paramMap.get('affiliateCode');

		try {
			const geoData = await this.fetchIPAndRegion();
			await this.saveReferralCode();
			this.trackerService.track('lol-referral-redirect', { affiliate: affiliate, ipAddress: geoData.ip, continent: geoData.continentCode, country: geoData.countryName });
		} catch {
			//
		}

		this.router.navigate(['/'], { replaceUrl: true });
	}
}
