import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { socialMediaLinks } from 'src/constants'
import { Icon } from 'src/components/atoms/icon/icon.component';
import { Router } from '@angular/router';

interface MenuGame {
	name: string;
	icon: Icon;
	entries: {
		icon: Icon;
		label: string;
		route: string;
	}[];
}

@Component({
	selector: 'leetify-app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly isBrowser: boolean;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly socialMediaLinks = socialMediaLinks;
	protected isDataSourcesSubmenuExpanded: boolean = false;
	protected isDiscordConnected: boolean;

	protected expandedMenus: string[] = JSON.parse(window.localStorage.getItem('expandedMenus')) || [];
	protected menuGames: MenuGame[] = [
		{
			name: 'CS2',
			icon: Icon.NAV_CS2_LOGO,
			entries: [
				{
					icon: Icon.NAV_MATCHES,
					label: 'Matches',
					route: '/matches',
				},
				{
					icon: Icon.NAV_DASHBOARD,
					label: 'Dashboard',
					route: '/matches',
				},
				{
					icon: Icon.NAV_GENERAL,
					label: 'General',
					route: '/matches',
				},
				{
					icon: Icon.NAV_CLUBS,
					label: 'My Clubs',
					route: '/matches',
				},
				{
					icon: Icon.NAV_FOCUSAREAS,
					label: 'Focus areas',
					route: '/matches',
				},
				{
					icon: Icon.NAV_AIM,
					label: 'Aim',
					route: '/matches',
				},
				{
					icon: Icon.NAV_UTIL,
					label: 'Utility',
					route: '/matches',
				},
				{
					icon: Icon.NAV_MAP,
					label: 'Maps',
					route: '/matches',
				},
				{
					icon: Icon.NAV_PRACSERVERS,
					label: 'Practice Servers',
					route: '/matches',
				},
			],
		},
		{
			name: 'LoL',
			icon: Icon.NAV_LOL_LOGO,
			entries: [
				{
					icon: Icon.NAV_MATCHES,
					label: 'Matches',
					route: '/lol/matches/list',
				},
			],
		},
	];


	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private router: Router
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	protected toggleDataSourcesSubmenu(isExpanded = !this.isDataSourcesSubmenuExpanded): void {
		this.isDataSourcesSubmenuExpanded = isExpanded;
	}

	protected async disconnectRiot(): Promise<void> {
	}

	protected async changeDiscordNotificationsSettings() {
	}

	protected logout(): void {
	}

	public ngOnInit(): void {
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	public toggleSubMenu(menuName: string): void {
		const index = this.expandedMenus.indexOf(menuName);
		if (index === -1) {
			this.expandedMenus.push(menuName);
		} else {
			this.expandedMenus.splice(index, 1);
		}

		window.localStorage.setItem('expandedMenus', JSON.stringify(this.expandedMenus));
	}

	public isSubMenuExpanded(menuName: string): boolean {
		return this.expandedMenus.includes(menuName);
	}

	public isActive(path: string) {
		return true;
	}
}
