<nav>
	<ul class="sidebar-menu">
		<li class="nav-section nav-home">
			<div class="nav-inner">
				<a [routerLink]="'/'" routerLinkActive="route-active" class="menu-entry">
					<leetify-icon [icon]="Icon.NAV_HOME" class="main-nav-icon"></leetify-icon>
					<span>Home</span>
				</a>
			</div>
		</li>

		<li *ngFor="let game of menuGames" class="nav-section nav-game game-{{ game.name }}" [ngClass]="{ '--expanded': isSubMenuExpanded(game.name) }">
			<div class="nav-inner">
				<button class="section-header" (click)="toggleSubMenu(game.name)">
					<leetify-icon [icon]="game.icon" class="main-nav-icon"></leetify-icon>
					<span>{{ game.name }}</span>
					<leetify-icon *ngIf="!isSubMenuExpanded(game.name)" [icon]="Icon.NAV_CARET_DOWN"></leetify-icon>
					<leetify-icon *ngIf="isSubMenuExpanded(game.name)" [icon]="Icon.NAV_MINUS"></leetify-icon>
				</button>
				<ul class="nav-submenu">
					<leetify-expandable-vertical [isActive]="isSubMenuExpanded(game.name)" [isAriaHiddenWhenInactive]="false">
						<li class="game-submenu" *ngFor="let entry of game.entries">
							<a class="user-menu-button menu-entry" [routerLink]="entry.route" routerLinkActive="route-active">
								<leetify-icon [icon]="entry.icon"></leetify-icon>
								<span>{{ entry.label }}</span>
							</a>
						</li>
					</leetify-expandable-vertical>
				</ul>
			</div>
		</li>
		<li class="nav-section nav-referrals">
			<div class="nav-inner">
				<a href="/">
					<leetify-icon [icon]="Icon.NAV_REFERRALS" class="main-nav-icon"></leetify-icon>
					<span>Referrals</span>
				</a>
			</div>
		</li>
	</ul>

	<div class="sidebar-social">
		<a
			[queryParams]="{ redirect: 'no' }"
			aria-label="Home Page"
			container="body"
			ngbTooltip="Go to Home Page"
			openDelay="300"
			routerLink="/"
			tooltipClass="default-tooltip"
		>
			<leetify-icon [icon]="Icon.HOME"></leetify-icon>
		</a>

		<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
			<leetify-icon [icon]="social.icon"></leetify-icon>
		</a>
	</div>
</nav>
